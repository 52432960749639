import React from "react";
import "./index.css";
import { formaterPrice } from "../../../Utils/FormaterPrice";

import i18n from "../../../i18n";

export default function Items(props: any) {
  const { data, title } = props;
  const domain = localStorage.getItem("domain");

  let totals = 0;
  let finalTotal = 0;

  data.items.forEach(function (items: any) {
    const t = items.items.quantity * items.items.price;
    totals += t;
    items.items.subItems.forEach((x: any) => {
      const s = x.quantity * x.price;
      totals += s * items.items.quantity;
      const t = x && x.subItems ? x.subItems : [];
      t.forEach((y: any) => {
        const u = y.quantity * y.price;
        totals += u * items.items.quantity;
      });
    });
  });

  console.log("data", data.deliveryCost);

  const tipo = data?.cuponTipe ? data?.cuponTipe : "";

  if (tipo === "porcentaje") {
    finalTotal = totals - (totals * data.cuponValue) / 100;
    if (finalTotal < 0) {
      finalTotal = 0;
    }
    if (domain === "blacksheeprestaurants.com") {
      finalTotal += data.tip + data.deliveryCost;
    }
  } else if (tipo === "dinero") {
    finalTotal = totals - data.cuponValue * 100;
    if (domain === "blacksheeprestaurants.com") {
      finalTotal += data.tip + data.deliveryCost;
    }
  } else {
    finalTotal = totals;
    if (domain === "blacksheeprestaurants.com") {
      finalTotal += data.tip + data.deliveryCost;
    }
  }

  return (
    <div className="items-cart">
      <h3>{title}</h3>

      <div className="items-cart-resumen">
        <div>
          <ul>
            <li>
              <p>
                {i18n.t("orders:products")}
                {":"}
              </p>
            </li>
            {domain === "blacksheeprestaurants.com" && (
              <>
                <li>
                  <p>
                    {i18n.t("orders:tips")}
                    {":"}
                  </p>
                </li>
                <li>
                  <p>
                    {i18n.t("orders:delivery")}
                    {":"}
                  </p>
                </li>
              </>
            )}
            <li>
              <p>
                {i18n.t("orders:discount")}
                {":"}
              </p>
            </li>
            <li>
              <h1>
                {i18n.t("orders:total")}
                {":"}
              </h1>
            </li>
          </ul>
        </div>

        <div style={{ marginLeft: "auto" }}>
          <ul>
            <li>
              <p style={{ textAlign: "right" }}>
                {formaterPrice(totals / 100, "es-ES", "EUR")}
              </p>
            </li>
            {domain === "blacksheeprestaurants.com" && (
              <>
                <li>
                  <p style={{ textAlign: "right" }}>
                    {formaterPrice(data.tip / 100, "es-ES", "EUR")}
                  </p>
                </li>
                <li>
                  <p style={{ textAlign: "right" }}>
                    {formaterPrice(data.deliveryCost / 100, "es-ES", "EUR")}
                  </p>
                </li>
              </>
            )}
            <li>
              <p style={{ textAlign: "right" }}>
                {" "}
                -{" "}
                {tipo === "porcentaje"
                  ? `${data.cuponValue}%`
                  : formaterPrice(data.discountTotal / 100, "es-ES", "EUR")}
              </p>
            </li>
            <li>
              <h1>{formaterPrice(finalTotal / 100, "es-ES", "EUR")}</h1>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
