import React from "react";
import "./index.css";
import Items from "./Items";
import Products from "./Products";
import Resumen from "./Resumen";
import PaymentType from "./PaymentType";
import Hora from "./Hora";
import Nota from "./Nota";
import Cupon from "./Cupon";
import i18n from "../../../i18n";

export default function DetailsCard(props: any) {
  const { data } = props;

  return (
    <div className="new-menu-cart-container">
      <div className="new-cart-wrapper">
        <div className="new-cart-content">
          <div className="carts">
            <Items
              title={i18n.t("orders:customerInfo")}
              title2={`${data.customerData.name} ${data.customerData.lastName}`}
              sub={data.customerData.telefono}
              sub1={data.customerData.email}
            />

            {data.courierData && data.courier ? (
              <Items
                title={i18n.t("orders:ridereInfo")}
                title2={`${data.courierData.name} ${data.courierData.lastName}`}
                sub={data.courierData.telefono}
                sub1={data.courierData.email}
              />
            ) : null}
            {data.orderType === "delivery" ? (
              <Items
                title={i18n.t("orders:deliveryAddress")}
                p={`${data.deliveryAddressData.formatted_address},
                ${data.deliveryAddressData.puertaPiso},
                ${
                  data.deliveryAddressData.postalcode
                    ? data.deliveryAddressData.postalcode
                    : i18n.t("orders:noZipCode")
                }`}
              />
            ) : null}
            {/* <Status title="Estado del pedido" data={data} /> */}
          </div>
          <div
            className="carts"
            style={{ width: "55%", marginRight: 40, minWidth: 500 }}
          >
            {
              !data.isBundled &&  <Products title={i18n.t("orders:products")} data={data} />
            }
            {/* <Products title={i18n.t("orders:products")} data={data} /> */}
          </div>
          <div className="carts">
            <Hora title={i18n.t("orders:deliveryTime")} data={data} />
            <Nota title={i18n.t("orders:orderNote")} nota={data.note} data={data} />
            {data.cupon ? <Cupon title={i18n.t("orders:coupon")} data={data} /> : null}
            <Resumen title={i18n.t("orders:summary")} data={data} />
            <PaymentType title={i18n.t("orders:paymentMethod")} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
